<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        步骤管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" @click="handleAdd">新增步骤</el-button>
      </div>
       <!-- 当前接口信息展示 -->
       <el-table :data="tableDataStep" style="width: 100%">
        <el-table-column
          prop="step_id"
          label="ID">
        </el-table-column>
        <el-table-column
          prop="step_name"
          label="步骤名称">
        </el-table-column>
        <el-table-column
          prop="step_obj"
          label="操作对象">
        </el-table-column>
        <el-table-column
          prop="action_id"
          label="操作动作">
        </el-table-column>
        <el-table-column
          prop="action_value"
          label="操作值">
        </el-table-column>
        <el-table-column
          prop="step_type"
          label="步骤类型">
        </el-table-column>
        <el-table-column
          prop="description"
          label="步骤描述">
        </el-table-column>
        <el-table-column
          prop="status"
          label="步骤状态">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 测试接口分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[1, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <!-- 新增编辑弹窗 -->
    <el-dialog
      :title="isAdd?'新增接口':'编辑接口'"
      :visible.sync="dialogVisibleStep"
      width="50%"
      @close="closeDialogHandle">
      <el-form :model="ruleFormStep" :rules="FormRulesStep" ref="ruleFormRefStep" label-width="120px" class="demo-ruleForm">
        <el-form-item v-if="!isAdd" label="步骤ID">
          <el-input v-model="ruleFormStep.step_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="步骤名称" prop="step_name">
          <el-input v-model="ruleFormStep.step_name"></el-input>
        </el-form-item>
        <el-form-item label="操作对象" prop="step_name">
          <!-- <el-input v-model="ruleFormStep.step_obj"></el-input> -->
          <el-cascader
            v-model="pageElCascaderValue"
            :options="elementCascaderOptions"
            :props="{ expandTrigger: 'hover', label: 'name', value: 'id', checkStrictly: true }"
            @change="pageHandleChange"
            style="width: 100%;">
          </el-cascader>
        </el-form-item>
        <el-form-item label="操作动作" prop="step_name">
          <!-- <el-input v-model="ruleFormStep.action_id"></el-input> -->
          <el-select v-model="ruleFormStep.action_id" placeholder="请选择" filterable clearable>
            <el-option
              v-for="item in selectOptionsActionAll"
              :key="item.id"
              :label="item.action_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作值" prop="step_name">
          <el-input v-model="ruleFormStep.action_value" placeholder="值为0 表示该动作不需要值"></el-input>
        </el-form-item>
        <el-form-item label="步骤类型" prop="step_name">
          <!-- <el-input v-model="ruleFormStep.step_type"></el-input> -->
          <el-select v-model="ruleFormStep.step_type" placeholder="请选择" filterable clearable disabled>
            <el-option
              v-for="item in selectOptionsStepType"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="步骤状态" prop="step_name">
          <el-switch v-model="ruleFormStep.status" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="步骤描述" prop="description">
          <el-input type="textarea" v-model="ruleFormStep.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleStep = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAddStep">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEditStep">编 辑</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryStepList, addStep, editStep, deleteStep } from '@/services/uiStep.js'
import { queryActionById } from '@/services/uiAction.js'
import { queryCascader } from '@/services/uiCascader.js'
import { queryElementById } from '@/services/uiElement.js'
export default {
  name: 'UiStep',
  data () {
    return {
      isAdd: true,
      tableDataStep: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      dialogVisibleStep: false,
      ruleFormStep: {},
      FormRulesStep: {
        step_name: [
          { required: true, message: '请输环境名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入环境描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ]
      },
      selectOptionsStepType: [
        {
          id: '1',
          label: '页面类型'
        }, {
          id: '2',
          label: '元素类型'
        }
      ],
      selectOptionsActionAll: [],
      pageElCascaderValue: [],
      elementCascaderOptions: []
    }
  },
  created () {
    this.loadGetStepList()
  },
  methods: {
    async loadGetStepList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryStepList(queryObj)
      if (res.status === 200) {
        this.tableDataStep = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    async loadGetStepAll () {
      const queryObj = {
        query_type: 'query_all'
      }
      const { data: res } = await queryActionById(queryObj)
      if (res.status === 200) {
        this.selectOptionsActionAll = res.data
      }
    },
    async loadGetCascader () {
      const queryObj = {
        cascader_type: 'element',
        order: 'element'
      }
      const { data: res } = await queryCascader(queryObj)
      if (res.status === 200) {
        this.elementCascaderOptions = res.data
      }
    },
    // 接口信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadGetStepList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadGetStepList()
      console.log(`当前页: ${val}`)
    },
    handleAdd () {
      console.log('新增动作')
      this.isAdd = true
      this.ruleFormStep = {}
      this.pageElCascaderValue = []
      this.dialogVisibleStep = true
      this.loadGetCascader()
      this.loadGetStepAll()
    },
    async handleEdit (rowData) {
      console.log('编辑动作')
      console.log(rowData)
      this.isAdd = false
      this.ruleFormStep = rowData
      this.loadGetCascader()
      this.loadGetStepAll()
      if (rowData.step_type === '1') {
        this.pageElCascaderValue = [rowData.step_obj]
      } else {
        const queryObj = {
          query_type: 'query_detail',
          element_id: rowData.step_obj
        }
        const { data: res } = await queryElementById(queryObj)
        if (res.status === 200) {
          this.pageElCascaderValue = [res.data.page_id, rowData.step_obj]
        }
        console.log(this.pageElCascaderValue)
      }
      this.dialogVisibleStep = true
    },
    handleDel (rowData) {
      console.log('删除动作')
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { step_id: rowData.step_id }
        const { data: res } = await deleteStep(deleteObj)
        if (res.status === 200) {
          this.$message.success('删除用例成功')
          this.loadGetStepList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async processAddStep () {
      console.log(this.ruleFormStep)
      const addObj = this.ruleFormStep
      const { data: res } = await addStep(addObj)
      if (res.status === 200) {
        this.dialogVisibleStep = false
        this.$message.success('添加成功')
        this.loadGetStepList()
      }
    },
    async processEditStep () {
      const editObj = this.ruleFormStep
      const { data: res } = await editStep(editObj)
      if (res.status === 200) {
        this.dialogVisibleStep = false
        this.$message.success('添加成功')
        this.loadGetStepList()
      }
    },
    closeDialogHandle () {
      this.$refs.ruleFormRefStep.resetFields()
      this.loadGetStepList()
    },
    pageHandleChange () {
      console.log(this.pageElCascaderValue)
      // this.pageElCascaderValue 值为列表形式，最后一个值为所选对象id，将它赋予所属页面
      // this.ruleFormPage.project_id = this.pageElCascaderValue[0]
      // this.ruleFormPage.module_id = this.pageElCascaderValue[1]
      this.ruleFormStep.step_obj = this.pageElCascaderValue[this.pageElCascaderValue.length - 1]
      if (this.pageElCascaderValue.length < 2) {
        this.ruleFormStep.step_type = '1'
      } else this.ruleFormStep.step_type = '2'
    }
  }
}
</script>

<style>

</style>
